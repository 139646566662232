import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const TrustpilotMicroWidget = ({ backgroundColour }) => {
    const [companyData, setCompanyData] = useState()

    useEffect(() => {
        fetch(
            `https://api.trustpilot.com/v1/business-units/${process.env.GATSBY_TRUSTPILOT_BUSINESS_UNIT_ID}?apikey=${process.env.GATSBY_TRUSTPILOT_API_KEY}`
        )
            .then((response) => response.json())
            .then((resultData) => {
                setCompanyData(resultData)
            })
    }, [])

    const companyInfo = companyData || []

    const trustScore = companyInfo.score?.trustScore

    const totalReviews = companyInfo.numberOfReviews?.usedForTrustScoreCalculation || ''
    const totalReviewsFormatted = totalReviews.toLocaleString()

    return (
        <div className="c-trustpilot-micro">
            <a
                className="c-trustpilot-micro__link"
                href="https://uk.trustpilot.com/review/protectline.co.uk"
                target="_blank"
                rel="noreferrer"
            >
                {backgroundColour === 'gradient' || backgroundColour === 'dark' ? (
                    <StaticImage
                        src={'../../../images/trustpilot-logo-white.png'}
                        width={120}
                        alt={'Trustpilot logo'}
                        className={'c-trustpilot-micro__logo'}
                    />
                ) : (
                    <StaticImage
                        src={'../../../images/trustpilot-logo.png'}
                        width={120}
                        alt={'Trustpilot logo'}
                        className={'c-trustpilot-micro__logo'}
                    />
                )}

                <span className="c-trustpilot-micro__content">
                    Protect Line is rated <strong>{trustScore} out of 5</strong> based on{' '}
                    <strong>{totalReviewsFormatted}</strong> reviews
                </span>
            </a>
        </div>
    )
}

export default TrustpilotMicroWidget
